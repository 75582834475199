import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "wrapper"];

  static values = {
    searchEnabled: Boolean,
    placeholder: String,
    searchPlaceholder: String,
  };

  connect() {
    console.log("Choices controller connected");
    this.initializeChoices();
  }

  async initializeChoices() {
    try {
      const [{ default: Choices }] = await Promise.all([
        import("choices.js"),
        import("choices.js/public/assets/styles/choices.min.css"),
      ]);

      if (!this.selectTarget) {
        console.error("Select target not found");
        return;
      }

      const placeholderValue = this.selectTarget.dataset.choicesPlaceholderValue || "Select an option...";
      const searchPlaceholderValue = this.selectTarget.dataset.choicesSearchPlaceholderValue || "Type to search...";
      const isMultiple = this.selectTarget.multiple;

      this.choices = new Choices(this.selectTarget, {
        searchEnabled: this.hasSearchEnabledValue ? this.searchEnabledValue : true,
        placeholder: true,
        placeholderValue,
        searchPlaceholderValue,
        removeItemButton: true,
        position: "auto",
        shouldSort: false,
        searchResultLimit: 10,
        renderSelectedChoices: isMultiple ? "always" : "auto",
        fuseOptions: {
          threshold: 0.3,
        },
      });

      // Show the wrapper once Choices is fully initialized
      if (this.hasWrapperTarget) {
        this.wrapperTarget.classList.remove("invisible");
      }
    } catch (error) {
      console.error("Failed to initialize Choices:", error);
    }
  }

  disconnect() {
    if (this.choices) {
      this.choices.destroy();
      this.choices = null;
    }
  }
}
