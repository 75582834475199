import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["canvas", "signatureData"];

  async connect() {
    const { default: SignaturePad } = await import("signature_pad");

    this.signaturePad = new SignaturePad(this.canvasTarget, {
      penColor: getComputedStyle(this.canvasTarget)
        .getPropertyValue("--signature-color") || "rgb(14, 116, 144)",
      minWidth: 1,
      maxWidth: 2.5,
      velocity: 0.7,
    });

    this.resizeCanvas();
    this.boundResize = () => this.resizeCanvas();
    window.addEventListener("resize", this.boundResize);

    this.signaturePad.addEventListener("endStroke", () => {
      this.updateSignatureData();
    });
  }

  clear() {
    this.signaturePad.clear();
    this.signatureDataTarget.value = "";
  }

  updateSignatureData() {
    this.signatureDataTarget.value = this.signaturePad.isEmpty()
      ? ""
      : this.signaturePad.toDataURL();
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = this.canvasTarget;
    const data = this.signaturePad.toDataURL(); // Save existing data

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    this.signaturePad.clear();
    if (data) {
      this.signaturePad.fromDataURL(data); // Restore after resize
    }
  }

  isEmpty() {
    return this.signaturePad.isEmpty();
  }

  disconnect() {
    window.removeEventListener("resize", this.boundResize);
  }
}
